@import "~@/uikit/colors.styl";

.search {
  padding: 0 0 15px 12px;
  border-bottom: 1px solid var(--theme-highlight);
  margin-bottom: 15px;
}

.wrapper {
  position: absolute;
  top: 50%;
  right: 50%;
  transform: translate(50%,-50%);
  z-index: 101;
}

.paranja {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(5px);
  z-index: 100;
}

.hidden {
  display: none;
}