@import "~@/uikit/colors.styl";

.root {
  background-color: var(--theme-module);
  overflow: hidden;
  position: relative;
}

.rounded {
  border-radius: 20px;
}

.border {
  border: 1px solid var(--theme-highlight);
}
