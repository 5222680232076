@import "~@/uikit/colors.styl";

.root {
  height: 52px;
  color: var(--theme-maintext);
  display: flex;
  align-items: center;
  border-radius: 20px;
  border: 1px solid var(--theme-highlight);
}

.title {
  flex: 1;
  font-size: 20px;
  font-weight: 600;
  margin: 0 20px;
}

.icon {
  height: 52px;
  width: 52px;
  border-radius: 20px;
  border: 1px solid var(--theme-highlight);
  margin-left: -1px;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    height: 24px;
    width: 24px;
  }
}

.close {
  cursor: pointer;
  width: 52px;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    height: 14px;
    width: 14px;
  }
}