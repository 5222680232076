@import "~@/uikit/colors.styl";

.root {
  display: flex;
  flex-direction: column;
  border-radius: 20px;
  border: 1px solid highlight_light;
  background: rgba(244, 244, 244, 0.15);
  box-shadow: 0 10px 40px 0 rgba(0, 0, 0, 0.05);
  backdrop-filter: blur(15px);
  padding: 30px;
  gap: 30px;
}

.link {
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;

  &Container {
    display: flex;
    gap: 20px;
    align-items: center;
  }

  &Label {
    flex: 1;
  }

  &Arrow {
    display: flex;
    svg {
      transform: rotate(90deg);
    }
  }
}