@import "~@/uikit/colors.styl";

.root {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  font-size: 20px;
  font-weight: 500;
  color: subtext;
  gap: 9px;
  background-color: var(--theme-module);
  border-radius: 20px;
  padding: 16px;
}
