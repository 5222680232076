@import "~@/uikit/colors.styl";

.root {
  display: none;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(5px);
  z-index: 1100;

  &.visible {
    display: block;
  }
}

.contentWrapper {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.disclaimer {
  max-width: 390px;
  text-align: center;
  margin-top: 10px;
  color: subtext;

  a {
    color: brand_color;
    text-decoration: underline;
  }
}

.content {
  box-shadow: 0 9px 18px 0 rgba(0, 0, 0, 0.05);
  border: 1px solid var(--theme-highlight);
  border-radius: 20px;
  overflow: hidden;

  & > div {
    height: 100%;
  }
}

.loader {
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  background-color: white;
}