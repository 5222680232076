@import "~@/uikit/layout.styl";
@import "~@/uikit/variables.styl";
@import "~@/uikit/colors.styl";

.root {
  display: flex;
  position: relative;
  main-content-width();
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  height: 100%;
  gap: 10px;
}

.badge {
  font-size: 33.5px;
  color: var(--theme-maintext);
  margin-right: auto;
  display: flex;
  align-items: center;
  text-decoration: none;
  width: max-content;
}

.links {
  flex: 1;
  margin-left: 50px;
}

.logo {
  display: flex;
  height: 34px;
  width: 104px;
  background-image: var(--logo);
  background-size: contain;
}

.right {
  display: flex;
  gap: 20px;
  align-items: center;
  margin-left: auto;
  padding-left: 10px;
}

.burger {
  display: none;
  height: 40px;
  width: 40px;
  padding: 0;
  justify-content: center;
  background-color: #F3F3F3;

  svg {
    height: 22px;
    width: 22px;
  }
}

.sideMenuWrapper {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  height: calc(100vh - 100%);
  background-color: var(--theme-module);
  padding: 20px;
}

@media mqTablet {
  .right {
    visibility: hidden;
    width: 104px;
  }
}

@media mqSmartphone {
  .burger {
    display: flex;
  }
  .links, .right {
    display: none;
  }
}