@import "~@/uikit/layout.styl";
@import "~@/uikit/colors.styl";

.root {
  display: flex;
  flex-direction: column;
  height: 100vh;
  background-color: var(--theme-backing);
}

.header {
  width: 100%;
  height: 80px;
  box-shadow: 0 1px 0 0 var(--theme-highlight);
  background-color: var(--theme-module);
  z-index: 1001;
}

.notifications {
  position: fixed;
  top: 90px;
  right: 10px;
  z-index: 1002;
}

.contentWrapper {
  flex: 1;
  position: relative;
  overflow: hidden;
  display: flex;
  z-index: 1000;
}

.content {
  position: relative;
  main-content-width();
  overflow: scroll;
  display: flex;
  flex-direction: column;
}

.connectionManager {
  z-index: 1200;
}

.main {
  flex: 1;
}
