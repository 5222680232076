@import "~@/uikit/colors.styl";
@import "~@/uikit/variables.styl";

.root {
  display: flex;
  font-size: 12px;
  font-weight: 500;
  color: subtext;
  padding: 15px 0;
  column-gap: 40px;
  margin-top: 30px;
  border-top: 1px solid var(--theme-highlight);
}

.icon {
  color: subtext;
  display: inline-flex;
  margin-right: 10px;
  align-items: center;

  svg {
    height: 20px;
  }
}

.copyright {
  display: flex;
  flex: 1;
  align-items: center;
  white-space: nowrap;
  overflow: hidden;
}

.socialWrapper {
  display: flex;
  align-items: center;
  column-gap: 14px;
}

.social {
  cursor: pointer;
  display: flex;
  align-items: center;
  color: subtext;
  width: 18px;

  &:hover {
    color: brand_color;
  }
}

@media mqSmartphone {
  .copyright {
    visibility: hidden;
  }

  .root {
    padding: 13px 0;
    font-size: 14px;
  }
}