@import "~@/uikit/colors.styl";

.root {
  color: grey_font;
  height: 20px;
  width: 20px;
  cursor: pointer;
  user-select: none;

  &:hover {
    color: brand_color;
  }
}

.moon {
  margin-top: -2px;
}