@font-face {
  font-family: 'Gilroy';
  src: url('./fonts/Gilroy-Regular.eot');
  src: local('☺'), url('./fonts/Gilroy-Regular.woff') format('woff'), url('./fonts/Gilroy-Regular.ttf') format('truetype'), url('./fonts/Gilroy-Regular.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}

/** Generated by FG **/
@font-face {
  font-family: 'Gilroy';
  src: url('fonts/Gilroy-SemiBold.eot');
  src: local('☺'), url('./fonts/Gilroy-SemiBold.woff') format('woff'), url('./fonts/Gilroy-SemiBold.ttf') format('truetype'), url('./fonts/Gilroy-SemiBold.svg') format('svg');
  font-weight: 600;
  font-style: normal;
}

:-webkit-any-link {
  text-decoration: none;
  color: inherit;
}

:-moz-any-link {
  text-decoration: none;
  color: inherit;
}

:any-link {
  text-decoration: none;
  color: inherit;
}


body {
  font-family: 'Gilroy';
  background-color: #EDEEF0;
}
