@import "~@/uikit/colors.styl";

.root {
  position: relative;
  text-align: center;
  cursor: pointer;
  background-color: var(--static-notification-bg);
  color: var(--static-notification-color);
  padding: 2px;
}

.icon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 10px;
  width: 10px;
  height: 100%;
  display: flex;
  align-items: center;
}

.link {
  text-decoration: underline;
}