.root {
  padding: 8px 15px;
  border-radius: 20px;
  border: solid 1px var(--theme-highlight);
  background-color: var(--theme-module);
  font-size: 14px;
  font-weight: 500;
  line-height: 1.29;
  letter-spacing: normal;
  color: var(--theme-maintext);
  margin-bottom: 11px;
}