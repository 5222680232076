@import "~@/uikit/colors.styl";

.root {
  border: 1px solid var(--theme-highlight);
  border-radius: 16px;
  padding: 10px;
  cursor: pointer;
  user-select: none;
  display: flex;
  align-items: center;
  width: 295px;

  &:hover {
    background-color: var(--theme-highlight);
  }

  & + .item {
    margin-top: 20px;
  }
}

.icon {
  height: 40px;
  width: 40px;
  padding: 7px;
  background-color: var(--theme-backing);
  border-radius: 13px;
  display: flex;
  align-items: center;
  margin-right: 20px;
}

.container {
  flex: 1;
}

.title {
  font-size: 20px;
  line-height: 20px;
  font-weight: 500;
  color: var(--theme-maintext);
}

.description {
  font-size: 16px;
  line-height: 20px;
  font-weight: 500;
  color: grey_font;
  margin-top: 3px;
}